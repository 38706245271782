import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS

// Components
import Layout from 'components/Layout'
import TitleBannerImage from 'components/TitleBannerImage'
import GravityForm from 'components/GravityForm'

function bedankt({ pageContext }) {
  const {
    offerteAanvragen: {
      title,
      acf: {
        banner_image: {
          localFile: {
            childImageSharp: {
              fluid: bannerImage
            }
          }
        },
        h1_tag: h1Tag
      }
    }
  } = useStaticQuery(graphql`{
    offerteAanvragen: wordpressPage(wordpress_id: {eq: 263}) {
      title
      acf {
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }`)

  return (
    <Layout subFooter>
      <h1 className="d-none">{h1Tag}</h1>
      <TitleBannerImage image={bannerImage} pageContext={pageContext} breadCrumbTitle={title} title={title} className="row position-relative d-flex align-items-end" />
      <div className="container py-5"> 
        <GravityForm thanks preview id={2} />
      </div>
    </Layout>
  )
}

export default bedankt